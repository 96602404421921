.showcase {
  margin-top: 60px;
  position: relative;
  background: linear-gradient(to bottom, var(--light-accent), #fff);
  border-bottom: 2px solid var(--color-primary);
}

.showcase .username {
  padding: 5px 0 10px;
  border-bottom: 0.5px solid var(--light-accent);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.showcase .username p {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-dark);
}

.header__help {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.header__help p {
  color: var(--light-accent);
  font-size: 14px;
  text-decoration: underline;
}

.header__help a {
  display: inline-block;
  color: var(--light-accent);
  text-decoration: none;
}

.header__help > .help__icon {
  color: var(--light-accent);
  padding: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid var(--light-accent);
}

.slideshow__container {
  overflow: hidden;
  position: relative;
}

.slideshow__container .showcase__slider {
  width: 100%;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.slideshow__container .slides {
  width: 100%;
  height: 25vh;
  position: relative;
  display: inline-block;
}

.slides .contents {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.slides h2 {
  font-weight: 600;
  font-size: 35px;
  color: var(--color-primary);
  margin-bottom: 10px;
  text-wrap: wrap;
}

.slides .copy {
  margin: 15px 0;
  color: var(--color-primary);
  font-size: 22px;
  text-wrap: wrap;
}

.link {
  text-decoration: none;
  color: var(--document-white);
  background-color: var(--color-primary);
  padding: 10px 20px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 10px;
}

.link:hover {
  opacity: 0.9;
}

.link > .icon {
  margin-left: 10px;
  color: var(--color-light);
  font-size: 16px;
  transition: all 0.3s ease;
}

.link:hover {
  opacity: 0.9;
}

.link:hover .icon {
  transform: translateX(2px);
}

.slideshow__dots {
  text-align: left;
  margin-bottom: 10px;
}

.slideshow__dots .dot {
  display: inline-block;
  height: 3px;
  width: 20px;
  cursor: pointer;
  background-color: var(--color-primary);
  margin-right: 15px;
}

.slideshow__dots .active {
  background-color: var(--color-light);
}

.service__cards {
  margin: 20px auto 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.service__cards::after {
  height: 0;
  content: "";
}

.arrows {
  position: absolute;
  top: 0;
  width: 100%;
  display: none;
}

.arrows .hide {
  display: none;
}

.arrows .arrowLeft {
  position: absolute;
  left: 0;
}

.arrows .arrowRight {
  position: absolute;
  right: 0;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .showcase {
    border-bottom: 1px solid var(--color-primary);
  }

  .showcase .username {
    padding: 3px 0;
  }

  .showcase .username p {
    font-size: 12px;
    margin-bottom: 0 !important;
  }

  .slideshow__container {
    height: 22vh;
  }

  .slideshow__container .slides {
    height: 22vh;
  }

  .slides h2 {
    font-size: 25px;
    margin-bottom: 5px;
    text-wrap: wrap;
  }

  .slides .copy {
    display: none;
  }

  .slides .link {
    padding: 4px 12px;
    border-radius: 2px;
    margin-top: 10px;
  }

  .slides .link > .icon {
    margin-left: 7px;
    font-size: 12px;
  }

  .service__cards {
    margin: 15px auto 40px;
  }

  .service__cards::after {
    width: 30%;
  }

  .arrows {
    display: inline-block;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .showcase {
    border-bottom: 1px solid var(--color-primary);
  }

  .showcase .username {
    padding: 3px 0;
  }

  .showcase .username p {
    font-size: 15px;
    margin-bottom: 0 !important;
  }

  .slideshow__container {
    height: 25vh;
  }

  .slideshow__container .slides {
    height: 25vh;
  }

  .slides h2 {
    font-size: 30px;
    margin-bottom: 7px;
    text-wrap: wrap;
  }

  .slides .copy {
    display: none;
  }

  .slides .link {
    padding: 4px 12px;
    border-radius: 2px;
    margin-top: 10px;
  }

  .slides .link > .icon {
    margin-left: 7px;
    font-size: 15px;
  }

  .service__cards {
    margin: 15px auto 40px;
  }

  .service__cards::after {
    width: 30%;
  }

  .arrows {
    display: inline-block;
  }
}

@media screen and (min-width: 425px) and (max-width: 559px) {
  .showcase {
    border-bottom: 1px solid var(--color-primary);
  }

  .showcase .username {
    padding: 3px 0;
  }

  .showcase .username p {
    font-size: 15px;
    margin-bottom: 0 !important;
  }

  .slideshow__container {
    height: 25vh;
  }

  .slideshow__container .slides {
    height: 25vh;
  }

  .slides h2 {
    font-size: 30px;
    margin-bottom: 10px;
    text-wrap: wrap;
  }

  .slides .copy {
    display: none;
  }

  .slides .link {
    padding: 4px 12px;
    border-radius: 2px;
    margin-top: 10px;
  }

  .slides .link > .icon {
    margin-left: 7px;
    font-size: 12px;
  }
  .service__cards {
    margin: 15px auto 30px;
  }

  .service__cards::after {
    width: 30%;
  }

  .arrows {
    display: inline-block;
  }
}

@media screen and (min-width: 560px) and (max-width: 767px) {
  .service__cards {
    margin: 15px auto 40px;
  }

  .service__cards::after {
    width: 30%;
  }

  .arrows {
    display: inline-block;
  }
}

@media screen and (min-width: 768px) and (max-width: 979px) {
  .service__cards {
    margin: 15px auto 50px;
  }

  .service__cards::after {
    width: 24%;
  }
}

@media screen and (min-width: 980px) and (max-width: 1023px) {
  .service__cards {
    margin: 15px auto 50px;
  }

  .service__cards::after {
    width: 24%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1119px) {
  .service__cards {
    margin: 15px auto 50px;
  }

  .service__cards::after {
    width: 24%;
  }
}

@media screen and (min-width: 1120px) and (max-width: 1365px) {
  .service__cards {
    margin: 15px auto 50px;
  }

  .service__cards::after {
    width: 24%;
  }
}

@media screen and (min-width: 1366px) {
  .service__cards {
    margin: 15px auto 40px;
  }

  .service__cards::after {
    width: 24%;
  }
}
