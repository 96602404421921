:root {
  --color-primary: #245054;
  --color-light: #f4cc2f;
  --light-accent: #d9d9d9;
  --text-dark: #1e1e1e;
  --document-white: #f4f4f4;
}

.container {
  margin-top: 60px;
  padding: 50px 0;
}

.heading {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.paragraph {
  text-align: center;
  font-size: 18px;
  margin: 20px auto;
  width: 50%;
}

.paragraph a {
  font-size: 16px;
  color: #333;
  text-decoration: underline;
}

.marketplace {
  text-align: center;
  margin: 60px auto 10px;
  width: 40%;
}

.button__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  gap: 5px;
  margin: 10px auto;
  border-top: 1px solid var(--color-primary);
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .container {
    margin-top: 60px;
    padding: 50px 0;
  }

  .paragraph {
    width: 90%;
  }

  .marketplace {
    width: 90%;
  }

  .button__group {
    width: 95%;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .container {
    margin-top: 60px;
    padding: 50px 0;
  }

  .paragraph {
    width: 90%;
  }

  .marketplace {
    width: 90%;
  }

  .button__group {
    width: 95%;
  }
}

@media screen and (min-width: 425px) and (max-width: 559px) {
  .container {
    margin-top: 60px;
    padding: 50px 0;
  }

  .paragraph {
    width: 90%;
  }

  .paragraph a {
    font-size: 16px;
    color: #333;
    text-decoration: underline;
  }

  .marketplace {
    width: 90%;
  }

  .button__group {
    width: 95%;
  }
}

@media screen and (min-width: 560px) and (max-width: 767px) {
  .container {
    margin-top: 60px;
    padding: 50px 0;
  }

  .paragraph {
    width: 70%;
  }

  .marketplace {
    width: 70%;
  }

  .button__group {
    width: 80%;
  }
}

@media screen and (min-width: 768px) and (max-width: 979px) {
  .container {
    margin-top: 60px;
    padding: 50px 0;
  }

  .paragraph {
    width: 70%;
  }

  .marketplace {
    width: 70%;
  }

  .button__group {
    width: 80%;
  }
}

@media screen and (min-width: 980px) and (max-width: 1023px) {
  .container {
    margin-top: 60px;
    padding: 50px 0;
  }

  .paragraph {
    width: 60%;
  }

  .marketplace {
    width: 60%;
  }

  .button__group {
    width: 60%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1119px) {
  .container {
    margin-top: 60px;
    padding: 50px 0;
  }

  .paragraph {
    width: 60%;
  }

  .marketplace {
    width: 60%;
  }

  .button__group {
    width: 60%;
  }
}

@media screen and (min-width: 1120px) and (max-width: 1365px) {
  .container {
    margin-top: 60px;
    padding: 50px 0;
  }

  .paragraph {
    width: 50%;
  }

  .marketplace {
    width: 50%;
  }

  .button__group {
    width: 50%;
  }
}

@media screen and (min-width: 1366px) {
  .container {
    margin-top: 60px;
    padding: 50px 0;
  }

  .paragraph {
    text-align: center;
    font-size: 18px;
    margin: 20px auto;
    width: 40%;
  }

  .marketplace {
    width: 40%;
  }

  .button__group {
    width: 40%;
  }
}
