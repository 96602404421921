:root {
  /* dark shades of primary color*/
}
/*=============== 
   Global Styles
  ===============*/

*,
::after,
::before {
 
}
/*  global classes */

.social-icons {
  display: none;
}
.iconButton {
  display: none;
}

@media screen and (min-width: 992px) {
 .iconButton {
  display: none;
}
}

@media screen and (max-width: 700px) {

  .slider-img{

      display:none ;

  }
  .prev,
  .next{

     display:none !important;
  
  }
  .social-icons {
    display: flex;
    color: white;


  }

   .iconButton {
    display: flex;
    color: white;
    zoom: 100%;    

  }

 .iconsList {
  list-style: none;
 }
}

.person-img {

  border-radius: 4%;
  margin-bottom: 1rem;
  width: 20%;
  height: 25%;
  object-fit: cover;
  border: 0.3px solid #155054;
  box-shadow: 0 0 3px #155054, 0 0 5px #FFFFFF;
  transition: transform 2s ease-;

}
.person-img:hover {
  
  transform: scale(2.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */

}

.slider-img{

  border-radius: 4%;
  margin-bottom: 1rem;
  margin:0px;
  width: 25%;
  height: 30%;
  object-fit: cover;
  border: 1.0px solid #155054;
 /* 
    box-shadow: 0 0 3px #155054, 0 0 5px #FFFFFF;
    transition: transform 2s ease-
 */ 
}

.slider-img:hover {

  position:relative;
  top: -4px;
  transform: scale(2.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}


.prev,

.next {
  position: absolute;
  bottom: 0px;
  transform: translateY(-50%);
  background: #155054;
  color: white;
  width: 1.25rem;
  height: 1.25rem;
  margin:35px; 
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: var(--radius);
  cursor: pointer;
  transition: var(--transition);
}
.prev:hover,
.next:hover {
  background: var(--clr-primary-5);
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
.listImage {

  margin:0px;
  width: 100%;
  height: 100%;
  object-fit: cover;

}
@media (min-width: 800px) {
 
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}
