@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,900;1,500;1,700&display=swap");

:root {
  --color-primary: #245054;
  --color-light: #f4cc2f;
  --light-accent: #d9d9d9;
  --text-dark: #1e1e1e;
  --document-white: #f4f4f4;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Roboto", sans-serif !important;
}

.container {
  width: 80%;
  margin: 0 auto;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .container {
    width: 90%;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .container {
    width: 90%;
  }
}

@media screen and (min-width: 425px) and (max-width: 559px) {
  .container {
    width: 90%;
  }
}

@media screen and (min-width: 560px) and (max-width: 767px) {
  .container {
    width: 85%;
  }
}

@media screen and (min-width: 768px) and (max-width: 979px) {
  .container {
    width: 85%;
  }
}

@media screen and (min-width: 980px) and (max-width: 1023px) {
  .container {
    width: 80%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1119px) {
  .container {
    width: 80%;
  }
}

@media screen and (min-width: 1120px) and (max-width: 1365px) {
  .container {
    width: 80%;
  }
}

@media screen and (min-width: 1366px) {
  .container {
    width: 80%;
  }
}
