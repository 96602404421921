:root {
  --color-primary: #245054;
  --color-light: #f4cc2f;
  --light-accent: #d9d9d9;
  --text-dark: #1e1e1e;
  --document-white: #f4f4f4;
}

.main__content__area {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.main__content__area .side__nav {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  position: relative;
}

.main__content__area .side__nav:after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: var(--light-accent);
}

.side__nav a {
  display: inline-block;
  margin: 10px 0;
  color: var(--color-primary);
  font-size: 18px;
  text-decoration: underline;
}

.side__nav a:hover {
  text-decoration: none;
}

.side__nav .help {
  position: absolute;
  bottom: 0;
}

.help a {
  margin-left: 10px;
  color: var(--text-dark);
  text-decoration: underline;
}

.help a:hover {
  text-decoration: none;
  color: var(--color-primary);
}

.main__content__area .service__cards {
  width: 75%;
  margin: 0 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .main__content__area {
    flex-direction: column;
  }

  .main__content__area .side__nav {
    width: 100%;
  }

  .main__content__area .side__nav::after {
    display: none;
  }

  .side__nav a {
    font-size: 16px;
  }

  .side__nav .help {
    top: 0;
    right: 0;
  }

  .main__content__area .service__cards {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 0.5px solid var(--light-accent);
  }

  .main__content__area .service__cards > * {
    width: 48%;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .main__content__area {
    flex-direction: column;
  }

  .main__content__area .side__nav {
    width: 100%;
  }

  .main__content__area .side__nav::after {
    display: none;
  }

  .side__nav a {
    font-size: 16px;
  }

  .side__nav .help {
    top: 0;
    right: 0;
  }

  .main__content__area .service__cards {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 0.5px solid var(--light-accent);
  }

  .main__content__area .service__cards > * {
    width: 48%;
  }
}

@media screen and (min-width: 425px) and (max-width: 559px) {
  .main__content__area {
    flex-direction: column;
  }

  .main__content__area .side__nav {
    width: 100%;
  }

  .main__content__area .side__nav::after {
    display: none;
  }

  .side__nav a {
    font-size: 16px;
  }

  .side__nav .help {
    top: 0;
    right: 0;
  }

  .main__content__area .service__cards {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 0.5px solid var(--light-accent);
  }

  .main__content__area .service__cards > * {
    width: 48%;
  }
}

@media screen and (min-width: 560px) and (max-width: 767px) {
  .main__content__area {
    flex-direction: column;
  }

  .main__content__area .side__nav {
    width: 100%;
  }

  .main__content__area .side__nav::after {
    display: none;
  }

  .side__nav a {
    font-size: 16px;
  }

  .side__nav .help {
    top: 0;
    right: 0;
  }

  .main__content__area .service__cards {
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 0.5px solid var(--light-accent);
  }

  .main__content__area .service__cards > * {
    width: 30%;
  }
}

@media screen and (min-width: 768px) and (max-width: 979px) {
  .main__content__area .service__cards > * {
    width: 30%;
  }
}

@media screen and (min-width: 980px) and (max-width: 1023px) {
  .main__content__area .service__cards > * {
    width: 30%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1119px) {
  .main__content__area .service__cards > * {
    width: 30%;
  }
}

@media screen and (min-width: 1120px) and (max-width: 1365px) {
  .main__content__area .service__cards > * {
    width: 30%;
  }
}

@media screen and (min-width: 1366px) {
  .main__content__area .service__cards > * {
    width: 30%;
  }
}
