:root {
  --color-primary: #245054;
  --color-light: #f4cc2f;
  --light-accent: #d9d9d9;
  --text-dark: #1e1e1e;
  --document-white: #f4f4f4;
}

.footer__bottom {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: var(--document-white);
  z-index: 9;
  border-top: 0.5px solid var(--color-primary);
}

.footer__inner {
  padding: 10px 0;
  color: var(--color-primary);
  text-align: center;
}

.footer__inner .footer__link {
  margin: 0 20px;
  color: var(--color-primary);
}

.footer__inner .footer__link:hover {
  text-decoration: none;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .footer__inner {
    padding: 7px 0;
  }

  .footer__inner .footer__link {
    margin: 0 10px;
    font-size: 14px;
  }
}
