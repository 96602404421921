:root {
  --color-primary: #245054;
  --color-light: #f4cc2f;
  --light-accent: #d9d9d9;
  --text-dark: #1e1e1e;
  --document-white: #f4f4f4;
}

.service__cards {
  flex-wrap: wrap;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .service__cards > * {
    width: 45%;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .service__cards > * {
    width: 45%;
  }
}

@media screen and (min-width: 425px) and (max-width: 559px) {
  .service__cards > * {
    width: 45%;
  }
}

@media screen and (min-width: 560px) and (max-width: 767px) {
  .service__cards > * {
    width: 30%;
  }
}

@media screen and (min-width: 768px) and (max-width: 979px) {
  .service__cards > * {
    width: 30%;
  }
}

@media screen and (min-width: 980px) and (max-width: 1023px) {
  .service__cards > * {
    width: 30%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1119px) {
  .service__cards > * {
    width: 24%;
  }
}

@media screen and (min-width: 1120px) and (max-width: 1365px) {
  .service__cards > * {
    width: 24%;
  }
}

@media screen and (min-width: 1366px) {
  .service__cards > * {
    width: 24%;
  }
}
