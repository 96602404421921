:root {
  --color-primary: #245054;
  --color-light: #f4cc2f;
  --light-accent: #d9d9d9;
  --text-dark: #1e1e1e;
  --document-white: #f4f4f4;
}

.service__card {
  border: 3px solid var(--color-primary);
  background-color: var(--light-accent);
  width: 24%;
  min-height: 130px;
  text-align: center;
  transition: all.3s ease;
  position: relative;
  cursor: pointer;
}

.service__card:hover {
  transform: scale(1.01);
}

.service__card a,
.service__card a:hover {
  text-decoration: none;
  color: var(--color-primary);
}

.service__card .card__link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service__card .card__link h2 {
  font-weight: 600;
  margin: 15px 0;
  font-size: 24px;
}

.service__card .card__link p {
  font-size: 14px;
  margin-bottom: 20px;
}

.service__card .card__link .card__icon {
  position: absolute;
  bottom: 0;
  margin: 10px 0 20px;
  font-size: 60px;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .service__card {
    border: 2px solid var(--color-primary);
    width: 30%;
    min-height: 110px;
    padding-bottom: 30px;
  }

  .service__card .card__link h2 {
    font-size: 18px;
  }

  .service__card .card__link p {
    font-size: 8px;
    margin-bottom: 5px;
  }

  .service__card .card__link .card__icon {
    position: absolute;
    bottom: 0;
    margin: 5px 0;
    font-size: 25px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .service__card {
    border: 2px solid var(--color-primary);
    width: 30%;    
    padding-bottom: 30px;
  }

  .service__card .card__link h2 {
    font-size: 18px;
  }

  .service__card .card__link p {
    font-size: 8px;
    margin-bottom: 5px;
  }

  .service__card .card__link .card__icon {
    position: absolute;
    bottom: 0;
    margin: 5px 0;    
    font-size: 30px;
  }
}

@media screen and (min-width: 425px) and (max-width: 559px) {
  .service__card {
    border: 2px solid var(--color-primary);
    width: 30%;
    padding-bottom: 30px;
  }

  .service__card .card__link h2 {
    font-size: 20px;
  }

  .service__card .card__link p {
    font-size: 10px;
    margin-bottom: 10px;
  }

  .service__card .card__link .card__icon {
    position: absolute;
    bottom: 0;
    margin: 5px 0;
    font-size: 30px;
  }
}

@media screen and (min-width: 560px) and (max-width: 767px) {
  .service__card {
    border: 2px solid var(--color-primary);
    width: 30%;
    padding-bottom: 40px;
  }

  .service__card .card__link h2 {
    font-size: 25px;
  }

  .service__card .card__link p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .service__card .card__link .card__icon {
    position: absolute;
    bottom: 0;
    margin: 7px 0;
    font-size: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 979px) {
  .service__card {
    border: 2px solid var(--color-primary);
    width: 30%;
    padding-bottom: 35px;
  }

  .service__card .card__link h2 {
    font-size: 22px;
  }

  .service__card .card__link p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .service__card .card__link .card__icon {
    position: absolute;
    bottom: 0;
    margin: 7px 0;
    font-size: 35px;
  }
}

@media screen and (min-width: 980px) and (max-width: 1023px) {
  .service__card {
    border: 2px solid var(--color-primary);
    width: 24%;
    padding-bottom: 40px;
  }

  .service__card .card__link h2 {
    font-size: 24px;
  }

  .service__card .card__link p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .service__card .card__link .card__icon {
    position: absolute;
    bottom: 0;
    margin: 7px 0;
    font-size: 35px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1119px) {
  .service__card {
    border: 2px solid var(--color-primary);
    width: 24%;
    padding-bottom: 40px;
  }

  .service__card .card__link h2 {
    font-size: 24px;
  }

  .service__card .card__link p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .service__card .card__link .card__icon {
    position: absolute;
    bottom: 0;
    margin: 7px 0;
    font-size: 35px;
  }
}

@media screen and (min-width: 1120px) and (max-width: 1365px) {
  .service__card {
    border: 2px solid var(--color-primary);
    width: 24%;
    padding-bottom: 40px;
  }

  .service__card .card__link h2 {
    font-size: 24px;
  }

  .service__card .card__link p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .service__card .card__link .card__icon {
    position: absolute;
    bottom: 0;
    margin: 7px 0;
    font-size: 35px;
  }
}

@media screen and (min-width: 1366px) {
  .service__card {
    border: 2px solid var(--color-primary);
    width: 24%;
    padding-bottom: 40px;
  }

  .service__card .card__link h2 {
    font-size: 24px;
  }

  .service__card .card__link p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .service__card .card__link .card__icon {
    position: absolute;
    bottom: 0;
    margin: 7px 0;
    font-size: 35px;
  }
}
